.banner{
    padding: 0 5%;  
    height: 450px;
    position: relative;
    z-index: 0;
}
.banner a{
    position: absolute;
    background-color: rgba(161,79,0,255);
    color: white;
    padding: 10px 20px;
    top: 10%;
    right: 6%;
    border-radius: 20px;
}
.banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.banner div{
    position: absolute;
    bottom: 0;
    left: 8%;
    background-color: rgba(0, 0, 0, 0.8);
    width: 40%;
    padding: 20px 40px;
    z-index: 0;
}
.banner h2{
    color: white;
    font-weight: 800;
}
.banner p{
    color: white;
}
.banner span{
    color: whitesmoke;
    font-size: 0.875rem;
}
.slogan h3{
	color: rgba(161,79,0,255);
    color: whitesmoke;
    text-shadow: 2px 2px rgba(161,79,0,255);
    font-size: 2rem;
    font-weight: 800;
    text-transform: capitalize;
    line-height: 1;
	width: 75%;
	margin: auto;
	padding: 25px 0;
	padding-bottom: 40px;
} 
.centered h3{
    width: 100%;
    color: #484f5e;
    font-family: 'Roboto', sans-serif;
    margin: 0 0 20px;
    padding: 0;
    padding-left: 10px;
    line-height: 1.1;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 1.2rem;
    margin: 0;
    margin: 10px 0;
    border-left: 5px solid green;
}
@media only screen and (max-width: 998px){
    .banner div{
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        width: 100%;
        padding: 10px 20px;
    }
    .banner a{
        position: absolute;
        background-color: rgba(161,79,0,1);
        color: white;
        padding: 10px 10px;
        top: 5%;
        right: 0;
        font-size: 1rem;
        border-radius: 0;
    }
    .slogan h3{
        color: rgba(161,79,0,255);
        color: rgba(161,79,0,1);
        text-shadow: 1px 1px rgba(161,79,0,1);
        font-size: 1.5rem;
        font-weight: 800;
        text-transform: capitalize;
        line-height: 1;
        width: 100%;
        margin: auto;
        padding: 10px 0;
        padding-bottom: 15px;
    } 
    .centered h3{
        padding-left: 5px;
        line-height: 1.1;
        font-weight: 600;
        text-transform: capitalize;
        font-size: 1rem;
    }
    .centered ul{
        padding: 0;
        margin: 0;
    }
    .centered li{
        list-style: none;
    }
}
/*
.centered{
    background: url("../../public/images/divine_logo.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
*/