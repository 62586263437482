.App {
  text-align: auto;
  box-sizing: border-box;
}
input, select, textarea{
  outline: none;
}
input[type='file']{
  border: none;
}

.App-link {
  color: #61dafb;
}
a{
  text-decoration: none;
  color: #898989;
}
.main_heading {
  padding-top: 25px;
  position: relative;
  margin-bottom: 65px;
  z-index: -1;
}
.main_heading h2{
  font-size: 1.6rem;
  font-weight: 500;
}
.main_heading::after {
  content: "";
  display: block;
  margin: 20px auto 0;
  width: 80px;
  height: 5px;
  background: rgba(161,79,0,255);
}
.activePage{
  border-bottom: 4px solid rgba(161,79,0,1);
}
h4 {
	font-size: 18px;
	color: #000;
	line-height: 21px;
	font-weight: 600;
	text-transform: none;
	margin: 30px 0 20px 0;
}
h5{
  font-size: 18px;
  border-left: 5px solid var(--background);
  padding-left: 10px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 998px){
  .main_heading h2{
    font-size: 1.2rem;
    font-weight: 500;
  }
  .activePage{
    border-bottom: 4px solid white;
  }
}