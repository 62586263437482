.bannerPage{
    background: url("../../public/images/studio.avif");
    height: 180px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 5%;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.page-title {
	color: #fff;
	font-size: 40px;
	font-weight: 700;
	line-height: 45px;
	margin-bottom: 15px;
}

.breadcrumb {
	background: transparent;
	padding: 0;
	margin: 0;
	color: #fff;
}

.breadcrumb li {
	font-weight: 500;
	font-size: 15px;
}
@media only screen and (max-width: 998px){
	.page-title {
		font-size: 25px;
		font-weight: 700;
	}
}