.contactForm input,
.contactForm select,
.contactForm textarea{
    padding: 10px;
    width: 100%;
    border: none;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    outline: none;
}
.contactForm{
    background-color: white;
    padding: 50px 20px;
}
.progress-bar {
    height: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #e0e0e0; 
    width: 70%;
}
.centered{
    padding: 0 5%;
}
progress::-moz-progress-bar { background: #e0e0e0; }
progress::-webkit-progress-value { background: #e0e0e0; }
progress { color: #e0e0e0; }