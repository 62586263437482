.top{
    padding: 0 5%;
    background-color: rgba(161,79,0,255);
}
.top li,
.top a{
    color: white;
}
.last a{
    padding: 10px;
    color: black;
}
.last{
    background: white;
    transition: 1s;
}
.last:hover{
    background: black;
    color: white;
}
.last:hover > a{
    color: white;
}
@media only screen and (max-width: 998px){
    .upper{
        overflow-x: auto;
        width: 100%;
    }
    .upper li{
        flex-shrink: 0;
    }
}