.AccountForm{
    width: 40%;
    position: relative;
    z-index: 1;
}
.AccountForm form{
    padding: 0 10px 20px 10px;
}
.AccountForm label{
    display: block;
    font-size: 0.875rem;
}
.AccountForm input{
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.875rem;
    margin-bottom: 8px;
}
.AccountForm textarea{
    width: 100%;
    height: 140px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.875rem;
}
.NoticeAdmin input{
    border: none;
    border: 1px solid #ddd;
}
.details p{
    margin: 8px 0;
    font-size: 0.875rem;
}
.imgDiv{
    position: relative;
}
.details img{
    width: 70%;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.1s;
}
.imgDiv img:hover{
    filter: grayscale(20%);
}
.datas{
    border: 1px solid #ddd;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px 20px;
}
@media only screen and (max-width: 998px){
    .AccountForm{
        width: 100%;
    }
}