.news img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: top;
}
.news div{
    padding: 10px 20px;
    background: white;
}
.news div div{
    padding: 10px 0;
}
.news span:first-child{
    background: rgba(161,79,0,1);
    color: white;
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 0;
}
.news span{
    color: grey;
}
.news h3{
    font-size: 16px;
    padding: 8px 0;
    font-weight: 700;
    color: rgba(161,79,0,255);
    transition: all .4s;
}
.news h3:hover{
    color: rgba(161,19,0,255);
    cursor: pointer;
}
.news_skeleton{
    width: 32%;
    flex-grow: 1;
    background-color: white;
}
.newsDiv{
    padding: 1.5% 5%;
}
.newsDiv h1{
    width: 100%;
    color: #484f5e;
    font-family: 'Roboto', sans-serif;
    margin: 0 0 20px;
    padding: 0;
    padding-left: 10px;
    line-height: 1.1;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 1.2rem;
    margin: 0;    
    margin: 10px 0;
    border-left: 5px solid green;
}
.newsDiv{
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
}
.newsDiv .flex{
    width: 31%;
}
.news{
    position: relative;
}
@media only screen and (max-width: 998px){
    .news_skeleton{
        width: 100%;
        flex-grow: 1;
        background-color: white;
        margin-bottom: 20px;
    }
    .newsDiv .flex{
        width: 100%;
    }
}
