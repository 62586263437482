.staff_div{
    padding: 0 5%;
    gap: 2%;
}
.staff div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: rgba(161,79,0,255);
    opacity: 0;
    transition: 1s;
    border-radius: 10px 0;
}
.staff{
    position: relative;
    width: 23%;
    margin-bottom: 20px;
    text-align: center;
}
.staff a{
    transition: 1s;
    color: white;
    font-size: 1.1rem;
}
.staff a:hover{
    color: lightblue;
}
.staff:hover > div{
    opacity: 1;
}
.staff p{
    color: white;
}
.staff img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: top;
    /*background: rgba(3, 73, 134, 0.5);*/
}
@media only screen and (max-width: 998px){
    .staff{
        width: 98%;
    }
    .staff img {
        border-radius: 10px;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: top;
        background: transparent;
    }
}