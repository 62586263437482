.clubs{
    padding: 0 5%;
}
.clubs p{
    width: 48%;
    flex-grow: 1;
    text-align: center;
    padding: 80px;
    border-radius: 10px;
    font-weight: 700;
    cursor: pointer;
    border-left: 5px solid var(--background);
}
.clubs p:first-child{
    background-color: var(--background);
    border-left: 5px solid var(--backgroundLight);
    color: white;
}
.clubs p:nth-child(2){
    background-color: var(--backgroundLight);
}
.clubs p:nth-child(3){
    background-color: var(--backgroundLight);
}
.clubs p:nth-child(4){
    background-color: var(--background);
    border-left: 5px solid var(--backgroundLight);
    color: white;
}

.side-panel{
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px;
}
.scrollable-content{
    padding: 0 5% 2.5% 5%;
}
.scrollable-content p{
    font-size: 14px;
}
.side-panel a{
    display: block;
    padding: 20px;
    background-color: var(--backgroundLight);
    margin: 5px 0;
    border-left: 10px solid var(--background);
    border-radius: 5px;
    color: black;
    font-size: 14px;
}
.h2{
    font-size: 20px;
    margin: 0;
    padding: 10px 0;
    font-weight: 700;
}
.image{
    width: 20%;
    height: 250px;
    cursor: pointer;
}
.image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.popupImage{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 50vh;
    text-align: center;
}
.popupImage img{
    width: auto;
    height: 100%;
}
@media only screen and (max-width: 998px){
    .image{
        width: 100%;
        height: 200px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .popupImage{
        width: 100%;
        height: auto;
    }
    .popupImage img{
        width: 100%;
        height: auto;
    }
    .side-panel{
        width: 100%;
        overflow: auto;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
    }
    .side-panel a{
        display: block;
        width: 140px;
        flex-shrink: 0;
        padding: 15px;
    }
}