.online{
    background: tomato;
    color: white;
    padding: 20px 18px;
    border-radius: 100%;
    position: fixed;
    bottom: 5%;
    right: 2.5%;
    z-index: 2;
    cursor: pointer;
    font-size: 0.9rem;
    animation-name: animatedPlay;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.online i{
    color: white;
    font-size: 1.8rem;
}
.onlineStream{
    width: 20%;
    background: white;
    border-radius: 10px;
    position: fixed;
    bottom: 17%;
    z-index: 5;
    right: 2.5%;
    padding: 10;
}
@keyframes animatedPlay{
    from{
        background: tomato;
        border: 2px solid green;
    }to{
        background: green;
        border: 2px solid tomato;
    }
}
@media only screen and (max-width: 998px){
    .onlineStream{
        width: 95%;
        margin: auto;
        bottom: 12%;
    }
    .online{
        background: tomato;
        color: white;
        padding: 10px 9px;
        border-radius: 100%;
        position: fixed;
        bottom: 2.5%;
        right: 2.5%;
        z-index: 2;
        cursor: pointer;
        font-size: 0.45rem;
        animation-name: animatedPlay;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}