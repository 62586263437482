.NewsDetails{
    padding: 0 5%;
    position: relative;
    z-index: 1;
}
.NewsDetails .inners{
    margin-top: 50px;
    position: relative;
    background: linear-gradient(to bottom, white, whitesmoke);
    border-radius: 10px 10px 0 0;
    padding: 10px 20px; 
}
.NewsDetails .inners img{
    margin: auto;
    display: block;
}
@media only screen and (max-width: 998px){
}