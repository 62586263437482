.Advertise{
    padding: 0 5%;
}
.Advertise h3{
    width: 100%;
    color: #484f5e;
    font-family: 'Roboto', sans-serif;
    margin: 0 0 20px;
    padding: 0;
    padding-left: 10px;
    line-height: 1.1;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 1.2rem;
    margin: 10px 0;
    border-left: 5px solid green;
}
.Advertise p{
    font-size: 0.875rem;
}
.Advertise table{
    width: 100%;
    border-collapse: collapse;
}
.Advertise thead{
    padding: 10px;
    background-color: rgba(161,79,0,255);
}
.Advertise table th{
    font-size: 0.775rem;
    color: white;
    padding: 10px 8px;
}
.Advertise table td{
    font-size: 0.775rem;
    padding: 10px;
}
.Advertise table tbody tr:nth-child(odd){
    background: white;
}
.Advertise table tbody tr:nth-child(even){
    background: #ddd;
}
.Advertise table tbody {
    display: block;
    max-height: 73vh;
    overflow: auto;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
.Advertise input{
    border: none;
    border: 1px solid #ddd;
    color: #333;
    outline: none;
}
.form .flex-grow-1{
    width: 33%;
}
.title{
    font-weight: 800;
    padding: 20px 0;
}