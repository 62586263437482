.home-container {
    display: flex;
    height: 100vh; /* for sticky positioning of scrollable content */
}
  
.side-panels {
    padding: 10px;
    flex-direction: column;
    width: 18%; /* Adjust the width as needed */
    height: 98vh; /* Set the height to full viewport height */
    position: sticky;
    top: 0;
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    z-index: 1;
}
  
.scrollable-contents {
    width: 82%;
    flex-grow: 1; /* Allow the right part to grow and take available space */
    padding: 20px; /* Add padding for better appearance */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
}
.side-panels a{
    display: block;
    padding: 20px;
    background-color: var(--backgroundLight);
    margin: 5px 0;
    border-left: 10px solid var(--background);
    border-radius: 5px;
    color: black;
    font-size: 14px;
    transition: .5s;
}
.side-panels a:hover{
    scale: 1.05;
}
.side-panels a:active{
    scale: 0.9;
}

@media only screen and (max-width: 998px){
    .home-container {
        display: block;
        height: auto;
    }
    .side-panels {
        padding: 10px;
        width: 100%;
        height: auto; 
        position: relative;
        display: flex;
        overflow-x: auto;
        /*flex-wrap: wrap*/
    }

    .side-panels a{
        width: auto;
        flex-shrink: 0;
        gap: 10px;
        display: block;
        max-width: 150px;
        padding: 10px;
        background-color: var(--backgroundLight);
        margin: 5px 5px;
    }

    .welcome img{
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
        margin-top: 20px;
    }
      
    .scrollable-content {
        width: 100%;
        flex-grow: 1; /* Allow the right part to grow and take available space */
        padding: 20px; /* Add padding for better appearance */
        overflow-y: auto; /* Enable vertical scrolling if content overflows */
    }
}