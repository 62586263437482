
.newsBanner{
    width: 100%;
    height: 220px;
    position: relative;
    overflow: hidden;
}
.newsBanner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transition: all 1s;
}
.newsBanner div{
    position: absolute;
    left: 5%;
    bottom: -16px;
    z-index: 1;
    transition: all 1s;
}
.newsBanner div h2{
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 1.5rem;
}
.newsBanner div h4{
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    display: inline-block;
    background-color: var(--background);
    font-size: 13px;
    padding: 5px;
    border-radius: 5px;
}
.newsBanner::after{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.7));
}
.newsBanner:hover img{
    transform: scale(1.1);
}
.newsBanner:hover div{
    bottom: 10%;
}
.entryNewsBanner{
    padding: 1% 5%; 
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
}
.entryNewsBanner .newBannerDiv:first-child {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 450px;
}
.entryNewsBanner .newBannerDiv:first-child .newsBanner{
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
}
.entryNewsBanner .newBannerDiv:nth-child(2){
    grid-column: 3 / 5;
}
@media only screen and (max-width: 998px){
    .newsBanner{
        height: 180px;
    }
    .entryNewsBanner .newBannerDiv:first-child {
        height: 180px;
        grid-column: 1 / 5;
        grid-row: 1 / 1;
    }
    .entryNewsBanner .newBannerDiv:first-child .newsBanner{
        height: 180px;
    }
    .entryNewsBanner .newBannerDiv:nth-child(2) {
        height: 180px;
        grid-column: 1 / 3;
    }
    .entryNewsBanner .newBannerDiv:nth-child(3) {
        height: 180px;
        grid-column: 3 / 5;
    }
    .entryNewsBanner .newBannerDiv:nth-child(4) {
        height: 180px;
        grid-column: 1 / 5;
    }
    .newsBanner div h2{
        font-weight: 600;
        font-size: 1rem;
    }
    .newsBanner div h4{
        font-weight: 600;
        font-size: 13px;
    }
    .newsBanner div{
        bottom: 15px;
    }
    .entryNewsBanner .newBannerDiv:nth-child(2) h2,
    .entryNewsBanner .newBannerDiv:nth-child(3) h2{
        font-size: 0.987rem;
    }
}