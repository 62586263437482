.productComponent .label label,
.scheduleComponent .label label,
.peopleComponent .label label,
.newsComponent .label label,
.clubComponent .label label,
.clubGalleryComponent .label label{
    width: 15%;
}
.productComponent .label input,
.scheduleComponent .label input,
.peopleComponent .label input,
.newsComponent .label input,
.clubComponent .label input,
.clubGalleryComponent .label input{
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.875rem;
    margin-bottom: 8px;
}
.productComponent .label select,
.scheduleComponent .label select,
.peopleComponent .label select,
.newsComponent .label select,
.clubComponent .label select,
.clubGalleryComponent .label select{
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.875rem;
    margin-bottom: 8px;
}
.productComponent .label textarea,
.productComponent .label textarea,
.peopleComponent .label textarea,
.newsComponent .label textarea,
.clubComponent .label textarea,
.clubGalleryComponent .label textarea{
    width: 100%;
    height: 140px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 0.875rem;
    margin-bottom: 8px;
}