.about_blog {
	background: #f5f5f5;
    margin: 0 5%;
}

.about_cont_blog {
	padding: 50px;
}
.about_cont_blog h3{
    width: 100%;
    color: #484f5e;
    font-family: 'Roboto', sans-serif;
    margin: 0 0 20px;
    padding: 0;
    padding-left: 10px;
    line-height: 1.1;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 1.2rem;
    margin: 10px 0;
    border-left: 5px solid green;
}
.about_cont_blog p {
	margin: 0;
	line-height: 24px;
    text-align: justify;
}

.about_cont_blog ul {
	margin-top: 15px;
}

.about_cont_blog ul li {
	width: 100%;
	margin: 5px 0;
	font-size: 15px;
	font-weight: 500;
    line-height: 24px;
    text-align: justify;
}
.about_cont_blog ul p{
    font-weight: bolder;
    background-color: rgba(161,79,0,255);
    color: white;
    padding: 10px;
}

.about_cont_blog ul li i {
	margin-right: 10px;
}

@media only screen and (max-width: 998px){
    .about_blog {
        background: #f5f5f5;
        margin: 0 0;
    }
    .about_cont_blog {
        padding: 20px;
    }
    .about_cont_blog ul {
        margin-top: 15px;
        padding: 0;
    }
    .about_cont_blog ul li {
        margin: 10px 0;
    }
}