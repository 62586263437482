
footer .top{
    padding: 1% 5%;
}
footer .top div{
    width: 25%;
}
footer .top h5{
    font-size: 1rem;
    border-bottom: 5px solid whitesmoke;
    margin: 20px 0;
}
footer .top p{
    color: white;
}
footer .top h5 span{
    background-color: whitesmoke;
    padding: 5px 10px;
}
footer .top ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
footer .top ul li{
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
}
footer .bottom{
    background-color: #000000A5;
    background: #743902;
    padding: 1% 5%;
}
footer .bottom h5{
    color: white;
    font-weight: 800;
    font-size: 1rem;
    padding-bottom: 10px;
}
footer .bottom p{
    font-size: 0.875rem;
    transition: 0.500s;
}
footer .bottom p:hover{
    color: white;
    cursor: pointer;
}
footer .bottom i{
    transition: 0.500s;
}
footer .bottom i:hover{
    color: white;
    cursor: pointer;
}
@media only screen and (max-width: 998px){
    footer .top div{
        width: 98%;
    }
}