.chat{
    padding: 0 5%;
}
.chat h3{
    width: 100%;
    color: #484f5e;
    font-family: 'Roboto', sans-serif;
    margin: 0 0 20px;
    padding: 0;
    padding-left: 10px;
    line-height: 1.1;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 1.2rem;
    margin: 10px 0;
    border-left: 5px solid green;
}
.chat label{
    width: 100%;
}
.chat .inner{
    width: 25%;
}
.chat .inner input,
.chat .inner select{
    padding: 8px;
    outline: none;
    margin-bottom: 10px;
    border: 1px solid #ddd;
}
.chat input{
    padding: 10px 8px;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 85%;
}
.msgDiv{
    height: 70vh;
    overflow: auto;
    background: rgb(244, 244, 244);
    padding: 20px;
}
.displayed{
    width: 75%;
}
.received{
    background-color: rgb(238, 148, 65);
    max-width: 75%;
}
.sent{
    background-color: rgb(146, 146, 146);
    max-width: 75%;
}
@media only screen and (max-width: 998px){
    .chat .inner{
        width: 100%;
    }
    .flexed{
        display: flex;
        gap: 20px;
    }
    .msgDiv{
        height: 40vh;
        overflow: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
    }
    .msgDiv::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    .displayed{
        width: 100%;
    }


}