.btn-active{
    background: rgba(161,79,0,255);
    color: white;
}
.btn:hover{
    background: rgba(161,79,0,255);
    color: white;
}
.programs{
    padding: 10px 0;
}
.programList{
    padding: 1% 5%;
}
.programList .inner{
    width: 32%;
    padding: 50px 20px;
    position: relative;
}
.programList .inner .positioned{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    overflow: hidden;
    transition: 1s;
    background-color: white;
}
.programList .inner .positioned div{
    padding: 10px 20px;
}
.programList .inner:hover > .positioned{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    overflow: hidden;
}
.programList h3,
.programList h4,
.programList h2{
    color: white;
    font-weight: 900;
    font-size: 1.5rem;
}
.programList h2{
    border-bottom: 2px solid white;
    padding-bottom: 10px;
}
.bg{
    background: rgba(161,79,0,255);
}
.programTable{
    width: 100%;
    border-collapse: collapse;
}
.programTable thead{
    padding: 10px;
    background-color: rgba(161,79,0,255);
}
.programTable th{
    font-size: 0.775rem;
    color: white;
    padding: 10px 8px;
}
.programTable td{
    font-size: 0.775rem;
    padding: 10px;
}
.programTable tbody tr:nth-child(odd){
    background: white;
}
.programTable tbody tr:nth-child(even){
    background: #ddd;
}
.programTable tbody {
    display: block;
    max-height: 73vh;
    overflow: auto;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
@media only screen and (max-width: 998px){
    .programList .inner{
        width: 100%;
        padding: 50px 20px;
        position: relative;
    }
    .progDiv{
        padding: 0 5%;
    }
    .progDiv p{
        text-align: justify;
    }
}